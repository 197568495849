import React from 'react';
import { graphql } from 'gatsby';
import { DisasterZone, ZoneMap } from '../components/disaster-zone';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageProps } from 'gatsby';
import { SiteMetadata } from '../types/site-metadata';

type DataProps = {
  site: SiteMetadata;
  allContentfulConservationMap: ZoneMap;
};

const ConservationMap: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="Conservation Map" />
      <DisasterZone posts={data.allContentfulConservationMap} />
    </Layout>
  );
};

export default ConservationMap;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulConservationMap(sort: { fields: [createdAt], order: DESC } filter: {contentful_id: {}, node_locale: {eq: "en-US"}}) {
      edges {
        node {
          title
          slug
          createdAt
          location {
            lon
            lat
          }
          mainImage {
            fluid(maxWidth: 1024) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          post {
            childMarkdownRemark {
              html
            }
          }
          rssFeedLinks {
            rssFeedLinks
          }
          researchGateLinks {
            researchGateLinks
          }
        }
      }
    }
  }
`;
